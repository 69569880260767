// Local storage keys

export const WAITING_FOR_ACCESS = "ADA_WAITING_FOR_ACCESS";

export const SHOW_INTRO_MESSAGE = "ADA_SHOW_INTRO_MESSAGE";

export const SHOW_SURVEY_POPUP = "ADA_SHOW_SURVEY_POPUP";

export const REGION = "ADA_REGION";

export const URL_HISTORY = "ADA_URL_HISTORY";

export const COPIED_POLICY = "ADA_COPIED_POLICY";

export const THEME_PREFERENCE = "ADA_THEME_PREFERENCE";

export const ACE_CE_THEME_PREFERENCE = "ADA_ACE_CODE_EDITOR_PREFERENCE";

export const ADA_DEVELOPER_MODE_ON = "ADA_DEVELOPER_MODE_ON";

export const PREFERS_POLICY_CAPS = "PREFERS_POLICY_CAPS";

export const PREFERS_VIN_CAPS = "PREFERS_VIN_CAPS";

export const TIMEZONE_PREFERENCE = "TIMEZONE_PREFERENCE";

export const PREFERS_ROUTE_TO_CREATED_POLICY = "PREFERS_ROUTE_TO_CREATED_POLICY";

export const RECENT_VINS = "RECENT_VINS";

export const PINNED_VINS = "PINNED_VINS";

export const PINNED_POLICIES = "PINNED_POLICIES";

export const USER_EVENT_REC_DATE = "USER_EVENT_REC_DATE";

export const FROM_FEATURES_PAGE = "FROM_FEATURES_PAGE";
